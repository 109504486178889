import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
} from "../actions/image/types";

const imageReducer = (state = false, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return true;
    case UPLOAD_IMAGE_FAILURE:
    case UPLOAD_IMAGE_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default imageReducer;
