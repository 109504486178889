export const GET_SUPPLIER = "GET_SUPPLIER";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAILURE = "GET_SUPPLIER_FAILURE";

export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const GET_ALL_SUPPLIERS_SUCCESS = "GET_ALL_SUPPLIERS_SUCCESS";
export const GET_ALL_SUPPLIERS_FAILURE = "GET_ALL_SUPPLIERS_FAILURE";

export const BAN_SUPPLIER = "BAN_SUPPLIER";
export const BAN_SUPPLIER_SUCCESS = "BAN_SUPPLIER_SUCCESS";
export const BAN_SUPPLIER_FAILURE = "BAN_SUPPLIER_FAILURE";

export const REJECT_SUPPLIER = "REJECT_SUPPLIER";
export const REJECT_SUPPLIER_SUCCESS = "REJECT_SUPPLIER_SUCCESS";
export const REJECT_SUPPLIER_FAILURE = "REJECT_SUPPLIER_FAILURE";

export const APPROVE_SUPPLIER = "APPROVE_SUPPLIER";
export const APPROVE_SUPPLIER_SUCCESS = "APPROVE_SUPPLIER_SUCCESS";
export const APPROVE_SUPPLIER_FAILURE = "APPROVE_SUPPLIER_FAILURE";
