import * as supplierTypes from "../../actions/supplier/types";

const reject = (state = [], action) => {
  switch (action.type) {
    case supplierTypes.REJECT_SUPPLIER:
      const newArray = state.concat([action.id]);
      return newArray;
    case supplierTypes.REJECT_SUPPLIER_FAILURE:
    case supplierTypes.REJECT_SUPPLIER_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default reject;

export const selectRejectingId = (state, idx) => {
  return state.reject.find((id) => id === idx);
};
