import * as productTypes from "../../actions/product/types";

const update = (state = [], action) => {
  switch (action.type) {
    case productTypes.ACCEPT_PRODUCT_REQUEST:
    case productTypes.REJECT_PRODUCT_REQUEST:
      const newArray = state.concat([action.id]);
      return newArray;
    case productTypes.ACCEPT_PRODUCT_REQUEST_FAILURE:
    case productTypes.ACCEPT_PRODUCT_REQUEST_SUCCESS:
    case productTypes.REJECT_PRODUCT_REQUEST_FAILURE:
    case productTypes.REJECT_PRODUCT_REQUEST_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default update;

export const selectUpdatingId = (state, id) => {
  return state.update.find((catID) => catID === id);
};
