import * as weeklyTipTypes from "../../actions/weeklyTip/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case weeklyTipTypes.GET_ALL_WEEKLYTIPS:
    case weeklyTipTypes.GET_WEEKLYTIP:
      return "fetching";
    case weeklyTipTypes.CREATE_WEEKLYTIP:
      return "creating";
    case weeklyTipTypes.UPDATE_WEEKLYTIP:
      return "updating";
    case weeklyTipTypes.CREATE_WEEKLYTIP_SUCCESS:
    case weeklyTipTypes.CREATE_WEEKLYTIP_FAILURE:
    case weeklyTipTypes.GET_ALL_WEEKLYTIPS_FAILURE:
    case weeklyTipTypes.GET_ALL_WEEKLYTIPS_SUCCESS:
    case weeklyTipTypes.GET_WEEKLYTIP_FAILURE:
    case weeklyTipTypes.GET_WEEKLYTIP_SUCCESS:
    case weeklyTipTypes.UPDATE_WEEKLYTIP_FAILURE:
    case weeklyTipTypes.UPDATE_WEEKLYTIP_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
