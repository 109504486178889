import axios from "axios";
import store from "../store";
import { logoutUserRequest } from "../store/actions/auth/login";
import { NotificationManager } from "react-notifications";

export const baseURL = process.env.REACT_APP_API_BASEURL;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem("@Emedic:Token");
  if (token) {
    config.headers["authorization"] = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // eslint-disable-next-line eqeqeq
    if (response?.data?.error?.message?.toLowerCase() == "invalid token") {
      store.dispatch(logoutUserRequest());
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      NotificationManager.error("Unauthorised");
      store.dispatch(logoutUserRequest());
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default axiosInstance;
