export const GET_ALL_MINIMUM_ORDER_CONFIG = "GET_ALL_MINIMUM_ORDER_CONFIG";
export const GET_ALL_MINIMUM_ORDER_CONFIG_SUCCESS =
  "GET_ALL_MINIMUM_ORDER_CONFIG_SUCCESS";
export const GET_ALL_MINIMUM_ORDER_CONFIG_FAILURE =
  "GET_ALL_MINIMUM_ORDER_CONFIG_FAILURE";

export const SET_MINIMUM_ORDER_CONFIG = "SET_MINIMUM_ORDER_CONFIG";
export const SET_MINIMUM_ORDER_CONFIG_SUCCESS =
  "SET_MINIMUM_ORDER_CONFIG_SUCCESS";
export const SET_MINIMUM_ORDER_CONFIG_FAILURE =
  "SET_MINIMUM_ORDER_CONFIG_FAILURE";
