export const GET_PARTNER = "GET_PARTNER";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_FAILURE = "GET_PARTNER_FAILURE";

export const GET_ALL_PARTNERS = "GET_ALL_PARTNERS";
export const GET_ALL_PARTNERS_SUCCESS = "GET_ALL_PARTNERS_SUCCESS";
export const GET_ALL_PARTNERS_FAILURE = "GET_ALL_PARTNERS_FAILURE";

export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAILURE = "CREATE_PARTNER_FAILURE";

export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";

export const DELETE_PARTNER = "DELETE_PARTNER";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_FAILURE = "DELETE_PARTNER_FAILURE";
