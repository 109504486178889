import * as orderTypes from "../../actions/order/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case orderTypes.GET_ALL_ORDERS:
    case orderTypes.GET_ORDER:
      return "fetching";
    case orderTypes.CREATE_ORDER:
      return "creating";
    case orderTypes.UPDATE_ORDER:
      return "updating";
    case orderTypes.APPROVE_ORDER:
      return "approving";
    case orderTypes.DECLINE_ORDER:
      return "declining";
    case orderTypes.GET_ALL_ORDERS_FAILURE:
    case orderTypes.GET_ALL_ORDERS_SUCCESS:
    case orderTypes.UPDATE_ORDER_FAILURE:
    case orderTypes.UPDATE_ORDER_SUCCESS:
    case orderTypes.CREATE_ORDER_FAILURE:
    case orderTypes.CREATE_ORDER_SUCCESS:
    case orderTypes.GET_ORDER_FAILURE:
    case orderTypes.GET_ORDER_SUCCESS:
    case orderTypes.APPROVE_ORDER_FAILURE:
    case orderTypes.APPROVE_ORDER_SUCCESS:
    case orderTypes.DECLINE_ORDER_FAILURE:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
