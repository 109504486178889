import * as surveyTypes from "../../actions/survey/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case surveyTypes.GET_ALL_SURVEYS_RESPONSE_SUCCESS: {
      return action.payload.map((survey) => survey._id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
