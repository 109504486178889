import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectDeletingId } from "./expunge";
import page, { selectPage } from "./page";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
  page,
});

export default rootReducer;

export const getAllCategories = (store) => {
  const categoryIds = selectAllId(store.category);
  const byIds = selectAllById(store.category);
  return categoryIds.map((id) => byIds[id]);
};

export const getCategory = (store, id) => {
  return selectById(store.category, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.category);
};

export const getDeletingId = (store) => {
  return (id) => selectDeletingId(store.category, id);
};

export const getPageInfo = (store) => {
  return selectPage(store.category);
};
