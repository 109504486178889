import * as weeklyTipTypes from "../../actions/weeklyTip/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case weeklyTipTypes.DELETE_WEEKLYTIP:
      const newArray = state.concat([action.id]);
      return newArray;
    case weeklyTipTypes.DELETE_WEEKLYTIP_FAILURE:
    case weeklyTipTypes.DELETE_WEEKLYTIP_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectExpungingId = (state, id) => {
  return state.expunge.find((prodID) => prodID === id);
};
