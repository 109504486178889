import * as supplierTypes from "../../actions/supplier/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case supplierTypes.GET_ALL_SUPPLIERS_SUCCESS: {
      return action.payload.map((supplier) => supplier?._id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
