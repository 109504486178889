import * as sectionTypes from "../../../actions/appContent/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case sectionTypes.GET_ALL_HOMEPAGE_SECTIONS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case sectionTypes.UPDATE_HOMEPAGE_SECTION_SUCCESS:
      const newByIds = { ...state, [action.payload.id]: action.payload.slide };
      return newByIds;
    case sectionTypes.CREATE_HOMEPAGE_SECTION_SUCCESS:
    case sectionTypes.GET_HOMEPAGE_SECTION_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.section,
      };
      return newByIds;
    }
    case sectionTypes.ADD_ITEM_TO_SECTION_SUCCESS: {
      const currentSection = state[action.sectionID];
      const newByIds = {
        ...state,
        [action.sectionID]: {
          ...currentSection,
          products: [action.payload, ...currentSection.products],
        },
      };
      return newByIds;
    }
    case sectionTypes.REMOVE_ITEM_FROM_SECTION_SUCCESS: {
      const currentSection = state[action.sectionID];
      const newByIds = {
        ...state,
        [action.sectionID]: {
          ...currentSection,
          products: currentSection.products.filter(
            (product) => product._id !== action.id,
          ),
        },
      };
      return newByIds;
    }
    case sectionTypes.DELETE_HOMEPAGE_SECTION_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
