import * as productTypes from "../../actions/product/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case productTypes.GET_ALL_REQUESTED_PRODUCTS_SUCCESS: {
      return action.payload.map((item) => item._id);
    }
    // case productTypes.REQUEST_PRODUCT_SUCCESS: {
    //     const newIds = [action.payload._id].concat(state)
    //     return newIds
    // }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
