import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import page, { selectPage } from "./page";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  page,
});

export default rootReducer;

export const getAllTransactions = (store) => {
  const transactionIds = selectAllId(store.transaction);
  const byIds = selectAllById(store.transaction);
  return transactionIds.map((id) => byIds[id]);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.transaction);
};

export const getPageInfo = (store) => {
  return selectPage(store.transaction);
};
