import * as transactionTypes from "../../actions/transaction/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case transactionTypes.GET_ALL_TRANSACTIONS_SUCCESS: {
      return action.payload?.data?.map((transaction) => transaction.id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
