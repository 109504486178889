import * as blogPostTypes from "../../actions/blogPost/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case blogPostTypes.GET_ALL_BLOGPOSTS:
    case blogPostTypes.GET_BLOGPOST:
      return "fetching";
    case blogPostTypes.CREATE_BLOGPOST:
      return "creating";
    case blogPostTypes.UPDATE_BLOGPOST:
      return "updating";
    case blogPostTypes.CREATE_BLOGPOST_SUCCESS:
    case blogPostTypes.CREATE_BLOGPOST_FAILURE:
    case blogPostTypes.GET_ALL_BLOGPOSTS_FAILURE:
    case blogPostTypes.GET_ALL_BLOGPOSTS_SUCCESS:
    case blogPostTypes.GET_BLOGPOST_FAILURE:
    case blogPostTypes.GET_BLOGPOST_SUCCESS:
    case blogPostTypes.UPDATE_BLOGPOST_FAILURE:
    case blogPostTypes.UPDATE_BLOGPOST_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
