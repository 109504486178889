import * as supplierTypes from "../../actions/supplier/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case supplierTypes.GET_ALL_SUPPLIERS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case supplierTypes.REJECT_SUPPLIER_SUCCESS: {
      const rejectedSupplier = state[action.id];
      if (!rejectedSupplier) return state;
      rejectedSupplier.rejected = false;
      return {
        ...state,
        [action.id]: rejectedSupplier,
      };
    }
    case supplierTypes.APPROVE_SUPPLIER_SUCCESS: {
      const approvedSupplier = state[action.id];
      if (!approvedSupplier) return state;
      approvedSupplier.approved = true;
      return {
        ...state,
        [action.id]: approvedSupplier,
      };
    }
    case supplierTypes.BAN_SUPPLIER_SUCCESS: {
      const bannedSupplier = state[action.id];
      if (!bannedSupplier) return state;
      bannedSupplier.active = false;
      return {
        ...state,
        [action.id]: bannedSupplier,
      };
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
