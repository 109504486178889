import * as productTypes from "../../actions/product/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case productTypes.GET_ALL_PRODUCTS_SUCCESS:
    case productTypes.SEARCH_PRODUCTS_SUCCESS: {
      return action.payload.products.map((category) => category._id);
    }
    case productTypes.CREATE_PRODUCT_SUCCESS: {
      const newIds = [action.payload._id].concat(state);
      return newIds;
    }
    case productTypes.DELETE_PRODUCT_SUCCESS: {
      const newIds = state.filter((id) => id !== action.id);
      return newIds;
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
