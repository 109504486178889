import * as productTypes from "../../actions/product/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case productTypes.DELETE_PRODUCT:
      const newArray = state.concat([action.id]);
      return newArray;
    case productTypes.DELETE_PRODUCT_FAILURE:
    case productTypes.DELETE_PRODUCT_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectDeletingId = (state, id) => {
  return state.expunge.find((catID) => catID === id);
};
