import * as blogPostTypes from "../../actions/blogPost/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case blogPostTypes.GET_ALL_BLOGPOSTS_SUCCESS: {
      return action.payload.map((blogPost) => blogPost._id);
    }
    case blogPostTypes.CREATE_BLOGPOST_SUCCESS:
    case blogPostTypes.GET_BLOGPOST_SUCCESS: {
      const newIds = state.includes(action.payload.id)
        ? state
        : [action.payload.id].concat(state);
      return newIds;
    }
    case blogPostTypes.DELETE_BLOGPOST_SUCCESS: {
      const newIds = state.filter((id) => id !== action.id);
      return newIds;
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
