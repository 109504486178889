import * as configTypes from "../../actions/config/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case configTypes.GET_ALL_MINIMUM_ORDER_CONFIG_SUCCESS: {
      return action.payload.map((config) => config._id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
