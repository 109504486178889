import * as productTypes from "../../actions/product/types";

const initialState = {
  currentPage: 0,
  totalCount: 0,
  totalPages: 0,
};

const page = (state = initialState, action) => {
  switch (action.type) {
    case productTypes.GET_ALL_PRODUCTS_SUCCESS:
      return {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalCount: action.payload.totalCount,
      };
    case productTypes.SEARCH_PRODUCTS_SUCCESS:
      return {
        currentPage: 0,
        totalPages: 0,
        totalCount: 0,
      };
    default:
      return state;
  }
};

export default page;

export const selectPage = (state) => {
  return state.page;
};
