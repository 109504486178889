import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import set, { selecSetId } from "./set";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  set,
});

export default rootReducer;

export const getAllMinimumOrderConfig = (store) => {
  const configsId = selectAllId(store.config);
  const byIds = selectAllById(store.config);
  return configsId.map((id) => byIds[id]);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.config);
};

export const getSetId = (store) => {
  return (id) => selecSetId(store.config, id);
};
