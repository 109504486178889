import * as deliveryTypes from "../../actions/delivery/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case deliveryTypes.GET_ALL_DELIVERIES_SUCCESS: {
      return action.payload.map((delivery) => delivery._id);
    }
    // case deliveryTypes.CREATE_CATEGORY_SUCCESS: {
    //     const newIds = [action.payload._id].concat(state)
    //     return newIds
    // }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
