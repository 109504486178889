import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById } from "./byIds";
import loading, { selectloadingState } from "./loading";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
});

export default rootReducer;

export const getAllSurveysResponseByID = (store) => {
  return selectAllById(store.survey);
};

export const getAllSurveysResponse = (store) => {
  const surveyIds = selectAllId(store.survey);
  const byIds = selectAllById(store.survey);
  return surveyIds.map((id) => byIds[id]);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.survey);
};
