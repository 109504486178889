import * as categoryTypes from "../../actions/category/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case categoryTypes.GET_CATEGORY:
      return "fetching";
    case categoryTypes.CREATE_CATEGORY:
      return "creating";
    case categoryTypes.UPDATE_CATEGORY:
      return "updating";
    case categoryTypes.GET_CATEGORY_FAILURE:
    case categoryTypes.GET_CATEGORY_SUCCESS:
    case categoryTypes.CREATE_CATEGORY_FAILURE:
    case categoryTypes.CREATE_CATEGORY_SUCCESS:
    case categoryTypes.UPDATE_CATEGORY_FAILURE:
    case categoryTypes.UPDATE_CATEGORY_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
