import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectDeletingId } from "./expunge";
import page, { selectPage } from "./page";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
  page,
});

export default rootReducer;

export const getAllProductsByID = (store) => {
  return selectAllById(store.product);
};

export const getAllProducts = (store) => {
  const productIds = selectAllId(store.product);
  const byIds = selectAllById(store.product);
  return productIds.map((id) => byIds[id]);
};

export const getProduct = (store, id) => {
  return selectById(store.product, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.product);
};

export const getDeletingId = (store) => {
  return (id) => selectDeletingId(store.product, id);
};

export const getPageInfo = (store) => {
  return selectPage(store.product);
};
