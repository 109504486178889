/* eslint-disable array-callback-return */
import * as transactionTypes from "../../actions/transaction/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case transactionTypes.GET_ALL_SETTLEMENTS_SUCCESS: {
      const byIds = {};
      action.payload?.data?.map((item) => {
        byIds[item.id] = item;
      });
      return byIds;
    }

    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state?.byIds;
};

export const selectById = (state, id) => {
  return state?.byIds[id];
};
