import * as supplierTypes from "../../actions/supplier/types";

const approve = (state = [], action) => {
  switch (action.type) {
    case supplierTypes.APPROVE_SUPPLIER:
      const newArray = state.concat([action.id]);
      return newArray;
    case supplierTypes.APPROVE_SUPPLIER_FAILURE:
    case supplierTypes.APPROVE_SUPPLIER_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default approve;

export const selectApprovingId = (state, idx) => {
  return state.approve.find((id) => id === idx);
};
