import axios from "./index";

export const _loginAdmin = (data) => {
  return axios.post(`/api/v1/admin/login`, data);
};

export const _registerAdmin = (data) => {
  return axios.post(`/api/v1/admin/register`, data);
};

export const _disableAdmin = (data) => {
  return axios.patch(`/api/v1/admin/deactivate`, data);
};

export const _inviteAdmin = (data) => {
  return axios.post(`/api/v1/admin/invite`, data);
};

export const _forgotPassword = (data) => {
  return axios.post(`/api/v1/admin/forgot-password`, data);
};

export const _resetPassword = (data) => {
  return axios.patch(`/api/v1/admin/reset-password`, data);
};

export const _updatePassword = (id, payload) => {
  return axios.patch(`/api/v1/admin/${id}/update-password`, payload);
};
