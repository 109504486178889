import * as blogPostTypes from "../../actions/blogPost/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case blogPostTypes.DELETE_BLOGPOST:
      const newArray = state.concat([action.id]);
      return newArray;
    case blogPostTypes.DELETE_BLOGPOST_FAILURE:
    case blogPostTypes.DELETE_BLOGPOST_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectExpungingId = (state, id) => {
  return state.expunge.find((prodID) => prodID === id);
};
