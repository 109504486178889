import * as sectionTypes from "../../../actions/appContent/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case sectionTypes.GET_ALL_HOMEPAGE_SLIDES:
    case sectionTypes.GET_HOMEPAGE_SLIDE:
      return "fetching";
    case sectionTypes.CREATE_HOMEPAGE_SLIDE:
      return "creating";
    case sectionTypes.UPDATE_HOMEPAGE_SLIDE:
      return "updating";
    case sectionTypes.CREATE_HOMEPAGE_SLIDE_SUCCESS:
    case sectionTypes.CREATE_HOMEPAGE_SLIDE_FAILURE:
    case sectionTypes.GET_ALL_HOMEPAGE_SLIDES_FAILURE:
    case sectionTypes.GET_ALL_HOMEPAGE_SLIDES_SUCCESS:
    case sectionTypes.GET_HOMEPAGE_SLIDE_FAILURE:
    case sectionTypes.GET_HOMEPAGE_SLIDE_SUCCESS:
    case sectionTypes.UPDATE_HOMEPAGE_SLIDE_FAILURE:
    case sectionTypes.UPDATE_HOMEPAGE_SLIDE_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
