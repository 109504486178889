import * as configTypes from "../../actions/config/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case configTypes.GET_ALL_MINIMUM_ORDER_CONFIG_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case configTypes.SET_MINIMUM_ORDER_CONFIG_SUCCESS: {
      const updatedConfig = state[action.id];
      updatedConfig.active = false;
      return {
        ...state,
        [action.id]: updatedConfig,
      };
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
