import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import ban, { selectBanningId } from "./ban";
import approve, { selectApprovingId } from "./approve";
import reject, { selectRejectingId } from "./reject";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  ban,
  approve,
  reject,
});

export default rootReducer;

export const getAllSuppliersByID = (store) => {
  return selectAllById(store.supplier);
};

export const getAllSuppliers = (store) => {
  const suppliersId = selectAllId(store.supplier);
  const byIds = selectAllById(store.supplier);
  return suppliersId.map((id) => byIds[id]);
};

export const getSupplier = (store, id) => {
  return selectById(store.supplier, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.supplier);
};

export const getBanningId = (store) => {
  return (id) => selectBanningId(store.supplier, id);
};
export const getRejectingId = (store) => {
  return (id) => selectRejectingId(store.supplier, id);
};
export const getApprovingId = (store) => {
  return (id) => selectApprovingId(store.supplier, id);
};
