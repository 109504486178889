import * as configTypes from "../../actions/config/types";

const ban = (state = [], action) => {
  switch (action.type) {
    case configTypes.SET_MINIMUM_ORDER_CONFIG:
      const newArray = state.concat([action.id]);
      return newArray;
    case configTypes.SET_MINIMUM_ORDER_CONFIG_FAILURE:
    case configTypes.SET_MINIMUM_ORDER_CONFIG_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default ban;

export const selecSetId = (state, idx) => {
  return state.ban.find((id) => id === idx);
};
