export const GET_WEEKLYTIP = "GET_WEEKLYTIP";
export const GET_WEEKLYTIP_SUCCESS = "GET_WEEKLYTIP_SUCCESS";
export const GET_WEEKLYTIP_FAILURE = "GET_WEEKLYTIP_FAILURE";

export const GET_ALL_WEEKLYTIPS = "GET_ALL_WEEKLYTIPS";
export const GET_ALL_WEEKLYTIPS_SUCCESS = "GET_ALL_WEEKLYTIPS_SUCCESS";
export const GET_ALL_WEEKLYTIPS_FAILURE = "GET_ALL_WEEKLYTIPS_FAILURE";

export const CREATE_WEEKLYTIP = "CREATE_WEEKLYTIP";
export const CREATE_WEEKLYTIP_SUCCESS = "CREATE_WEEKLYTIP_SUCCESS";
export const CREATE_WEEKLYTIP_FAILURE = "CREATE_WEEKLYTIP_FAILURE";

export const UPDATE_WEEKLYTIP = "UPDATE_WEEKLYTIP";
export const UPDATE_WEEKLYTIP_SUCCESS = "UPDATE_WEEKLYTIP_SUCCESS";
export const UPDATE_WEEKLYTIP_FAILURE = "UPDATE_WEEKLYTIP_FAILURE";

export const DELETE_WEEKLYTIP = "DELETE_WEEKLYTIP";
export const DELETE_WEEKLYTIP_SUCCESS = "DELETE_WEEKLYTIP_SUCCESS";
export const DELETE_WEEKLYTIP_FAILURE = "DELETE_WEEKLYTIP_FAILURE";
