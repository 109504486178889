import * as sectionTypes from "../../../actions/appContent/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case sectionTypes.DELETE_HOMEPAGE_SECTION:
    case sectionTypes.ADD_ITEM_TO_SECTION:
    case sectionTypes.REMOVE_ITEM_FROM_SECTION:
      const newArray = state.concat([action.id]);
      return newArray;
    case sectionTypes.DELETE_HOMEPAGE_SECTION_FAILURE:
    case sectionTypes.DELETE_HOMEPAGE_SECTION_SUCCESS:
    case sectionTypes.REMOVE_ITEM_FROM_SECTION_FAILURE:
    case sectionTypes.REMOVE_ITEM_FROM_SECTION_SUCCESS:
    case sectionTypes.ADD_ITEM_TO_SECTION_FAILURE:
    case sectionTypes.ADD_ITEM_TO_SECTION_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectExpungingId = (state, id) => {
  return state.expunge.find((prodID) => prodID === id);
};
