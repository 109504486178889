import * as appActions from "../actions/app/types";

const INIT_STATE = {
  sidebarShow: "responsive",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, { type, ...rest }) => {
  switch (type) {
    case appActions.SET_SIDEBAR:
      return { ...state, ...rest };
    default:
      return state;
  }
};
