import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
});

export default rootReducer;

export const getAllSurveysByID = (store) => {
  return selectAllById(store.survey);
};

export const getAllSurveys = (store) => {
  const surveyIds = selectAllId(store.survey);
  const byIds = selectAllById(store.survey);
  return surveyIds.map((id) => byIds[id]);
};

export const getSurveyByID = (store, id) => {
  return selectById(store.survey, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.survey);
};
