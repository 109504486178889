import * as productTypes from "../../actions/product/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case productTypes.GET_ALL_REQUESTED_PRODUCTS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = {
          ...item,
          supplierName: item.supplier?.name,
        };
      });
      return byIds;
    }
    case productTypes.ACCEPT_PRODUCT_REQUEST_SUCCESS: {
      const old = state[action.id];
      const newByIds = {
        ...state,
        [action.id]: {
          ...old,
          approved: true,
        },
      };
      return newByIds;
    }
    case productTypes.REJECT_PRODUCT_REQUEST_SUCCESS: {
      const old = state[action.id];
      const newByIds = {
        ...state,
        [action.id]: {
          ...old,
          cancelled: true,
        },
      };
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
