import * as categoryActions from "../../actions/category/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case categoryActions.GET_CATEGORY_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.categories.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case categoryActions.CREATE_CATEGORY_SUCCESS:
    case categoryActions.UPDATE_CATEGORY_SUCCESS: {
      const newByIds = { ...state, [action.payload._id]: action.payload };
      return newByIds;
    }
    case categoryActions.DELETE_CATEGORY_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
