import * as transactionTypes from "../../actions/transaction/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case transactionTypes.GET_ALL_SETTLEMENTS_SUCCESS: {
      return action.payload?.data?.map((settlements) => settlements?.id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state?.allIds;
};
