export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE";

export const BAN_CUSTOMER = "BAN_CUSTOMER";
export const BAN_CUSTOMER_SUCCESS = "BAN_CUSTOMER_SUCCESS";
export const BAN_CUSTOMER_FAILURE = "BAN_CUSTOMER_FAILURE";
