// require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` })
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";
import store from "./store/index";

React.icons = icons;

Sentry.init({
  dsn: "https://9ea0c693150f437c9c630989e81cfe96@o1020917.ingest.sentry.io/5986730",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <>
      <NotificationContainer />
      <App />
    </>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
