import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import page, { selectPage } from "./page";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  page,
});

export default rootReducer;

export const getAllSettlements = (store) => {
  const settlementIds = selectAllId(store.settlement);
  const byIds = selectAllById(store.settlement);
  return settlementIds?.map((id) => byIds[id]);
};

export const getSettlement = (store, id) => {
  return selectById(store.settlement, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.settlement);
};

export const getPageInfo = (store) => {
  return selectPage(store.settlement);
};
