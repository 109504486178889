import * as partnerTypes from "../../actions/partner/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case partnerTypes.GET_ALL_PARTNERS_SUCCESS: {
      return action.payload.map((partner) => partner._id);
    }
    case partnerTypes.CREATE_PARTNER_SUCCESS:
    case partnerTypes.GET_PARTNER_SUCCESS: {
      const newIds = state.includes(action.payload.id)
        ? state
        : [action.payload.id].concat(state);
      return newIds;
    }
    case partnerTypes.DELETE_PARTNER_SUCCESS: {
      const newIds = state.filter((id) => id !== action.id);
      return newIds;
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
