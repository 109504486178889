import * as categoryTypes from "../../actions/category/types";

const initialState = {
  currentPage: 0,
  totalPages: 0,
};

const page = (state = initialState, action) => {
  switch (action.type) {
    case categoryTypes.GET_CATEGORY_SUCCESS:
      return {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    default:
      return state;
  }
};

export default page;

export const selectPage = (state) => {
  return state.page;
};
