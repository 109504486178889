import {
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  SET_PROFILE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  INVITE_ADMIN,
  INVITE_ADMIN_FAILURE,
  INVITE_ADMIN_SUCCESS,
} from "../actions/auth/types";

const INIT_STATE = {
  isLoggedIn: false,
  profile: null,
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
    case FORGOT_PASSWORD:
    case REGISTER_USER:
    case RESET_PASSWORD:
    case CHANGE_PASSWORD:
    case INVITE_ADMIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
      };
    case LOGIN_USER_FAILURE:
    case REGISTER_USER_SUCCESS:
    case REGISTER_USER_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case CHANGE_PASSWORD_SUCCESS:
    case INVITE_ADMIN_FAILURE:
    case INVITE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT_USER:
      return INIT_STATE;
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};
