import * as customerTypes from "../../actions/customer/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case customerTypes.GET_ALL_CUSTOMERS_SUCCESS: {
      return action.payload.map((customer) => customer._id);
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
