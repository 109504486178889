export const GET_BLOGPOST = "GET_BLOGPOST";
export const GET_BLOGPOST_SUCCESS = "GET_BLOGPOST_SUCCESS";
export const GET_BLOGPOST_FAILURE = "GET_BLOGPOST_FAILURE";

export const GET_ALL_BLOGPOSTS = "GET_ALL_BLOGPOSTS";
export const GET_ALL_BLOGPOSTS_SUCCESS = "GET_ALL_BLOGPOSTS_SUCCESS";
export const GET_ALL_BLOGPOSTS_FAILURE = "GET_ALL_BLOGPOSTS_FAILURE";

export const CREATE_BLOGPOST = "CREATE_BLOGPOST";
export const CREATE_BLOGPOST_SUCCESS = "CREATE_BLOGPOST_SUCCESS";
export const CREATE_BLOGPOST_FAILURE = "CREATE_BLOGPOST_FAILURE";

export const UPDATE_BLOGPOST = "UPDATE_BLOGPOST";
export const UPDATE_BLOGPOST_SUCCESS = "UPDATE_BLOGPOST_SUCCESS";
export const UPDATE_BLOGPOST_FAILURE = "UPDATE_BLOGPOST_FAILURE";

export const DELETE_BLOGPOST = "DELETE_BLOGPOST";
export const DELETE_BLOGPOST_SUCCESS = "DELETE_BLOGPOST_SUCCESS";
export const DELETE_BLOGPOST_FAILURE = "DELETE_BLOGPOST_FAILURE";
