import * as categoryTypes from "../../actions/category/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case categoryTypes.DELETE_CATEGORY:
      const newArray = state.concat([action.id]);
      return newArray;
    case categoryTypes.DELETE_CATEGORY_FAILURE:
    case categoryTypes.DELETE_CATEGORY_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectDeletingId = (state, id) => {
  return state.expunge.find((catID) => catID === id);
};
