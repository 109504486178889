import * as categoryActions from "../../actions/category/types";

const allIds = (state = [], action) => {
  switch (action.type) {
    case categoryActions.GET_CATEGORY_SUCCESS: {
      return action.payload.categories.map((category) => category._id);
    }
    case categoryActions.CREATE_CATEGORY_SUCCESS: {
      const newIds = [action.payload._id].concat(state);
      return newIds;
    }
    case categoryActions.DELETE_CATEGORY_SUCCESS: {
      const newIds = state.filter((id) => id !== action.id);
      return newIds;
    }
    default:
      return state;
  }
};
export default allIds;

export const selectAllId = (state) => {
  return state.allIds;
};
