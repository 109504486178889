export const GET_SURVEY = "GET_SURVEY";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_SURVEY_FAILURE = "GET_SURVEY_FAILURE";

export const GET_ALL_SURVEYS = "GET_ALL_SURVEYS";
export const GET_ALL_SURVEYS_SUCCESS = "GET_ALL_SURVEYS_SUCCESS";
export const GET_ALL_SURVEYS_FAILURE = "GET_ALL_SURVEYS_FAILURE";

export const GET_ALL_SURVEYS_RESPONSE = "GET_ALL_SURVEYS_RESPONSE";
export const GET_ALL_SURVEYS_RESPONSE_SUCCESS =
  "GET_ALL_SURVEYS_RESPONSE_SUCCESS";
export const GET_ALL_SURVEYS_RESPONSE_FAILURE =
  "GET_ALL_SURVEYS_RESPONSE_FAILURE";

export const CREATE_SURVEY = "CREATE_SURVEY";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILURE = "CREATE_SURVEY_FAILURE";

export const TOGGLE_SURVEY = "TOGGLE_SURVEY";
export const TOGGLE_SURVEY_SUCCESS = "TOGGLE_SURVEY_SUCCESS";
export const TOGGLE_SURVEY_FAILURE = "TOGGLE_SURVEY_FAILURE";
