import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import ban, { selectBanningId } from "./ban";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  ban,
});

export default rootReducer;

export const getAllCustomersByID = (store) => {
  return selectAllById(store.customer);
};

export const getAllCustomers = (store) => {
  const customersId = selectAllId(store.customer);
  const byIds = selectAllById(store.customer);
  return customersId.map((id) => byIds[id]);
};

export const getCustomer = (store, id) => {
  return selectById(store.customer, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.customer);
};

export const getBanningId = (store) => {
  return (id) => selectBanningId(store.customer, id);
};
