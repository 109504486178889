import * as partnerTypes from "../../actions/partner/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case partnerTypes.GET_ALL_PARTNERS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case partnerTypes.UPDATE_PARTNER_SUCCESS:
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.partner,
      };
      return newByIds;
    case partnerTypes.CREATE_PARTNER_SUCCESS:
    case partnerTypes.GET_PARTNER_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.partner,
      };
      return newByIds;
    }
    case partnerTypes.DELETE_PARTNER_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
