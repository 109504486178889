import * as customerTypes from "../../actions/customer/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case customerTypes.GET_ALL_CUSTOMERS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case customerTypes.BAN_CUSTOMER_SUCCESS: {
      const bannedCustomer = state[action.id];
      bannedCustomer.active = false;
      return {
        ...state,
        [action.id]: bannedCustomer,
      };
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
