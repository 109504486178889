import * as transactionTypes from "../../actions/transaction/types";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalCount: 0,
};

const page = (state = initialState, action) => {
  switch (action.type) {
    case transactionTypes.GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        currentPage: action.payload.meta.page,
        totalPages: action.payload.meta.total,
        totalCount: action.payload.meta.total,
      };
    default:
      return state;
  }
};

export default page;

export const selectPage = (state) => {
  return state.page;
};
