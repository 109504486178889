import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectExpungingId } from "./expunge";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
});

export default rootReducer;

export const getAllBlogPostsByID = (store) => {
  return selectAllById(store.blogPost);
};

export const getAllBlogPosts = (store) => {
  const blogPostIds = selectAllId(store.blogPost);
  const byIds = selectAllById(store.blogPost);
  return blogPostIds.map((id) => byIds[id]);
};

export const getBlogPostByID = (store, id) => {
  return selectById(store.blogPost, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.blogPost);
};

export const getExpungingId = (store) => {
  return (id) => selectExpungingId(store.blogPost, id);
};
