import { combineReducers } from "redux";

import loading, { selectloadingState } from "./loading";

const rootReducer = combineReducers({
  loading,
});

export default rootReducer;

export const getLoadingState = (store) => {
  return selectloadingState(store.alert);
};
