export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_FAILURE = "GET_ALL_ORDERS_FAILURE";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const UPLOAD_ORDER_RECEIPT = "UPLOAD_ORDER_RECEIPT";
export const UPLOAD_ORDER_RECEIPT_SUCCESS = "UPLOAD_ORDER_RECEIPT_SUCCESS";
export const UPLOAD_ORDER_RECEIPT_FAILURE = "UPLOAD_ORDER_RECEIPT_FAILURE";

export const UPLOAD_ORDER_PRESCRIPTION = "UPLOAD_ORDER_PRESCRIPTION";
export const UPLOAD_ORDER_PRESCRIPTION_SUCCESS =
  "UPLOAD_ORDER_PRESCRIPTION_SUCCESS";
export const UPLOAD_ORDER_PRESCRIPTION_FAILURE =
  "UPLOAD_ORDER_PRESCRIPTION_FAILURE";

export const APPROVE_ORDER = "APPROVE_ORDER";
export const APPROVE_ORDER_SUCCESS = "APPROVE_ORDER_SUCCESS";
export const APPROVE_ORDER_FAILURE = "APPROVE_ORDER_FAILURE";

export const DECLINE_ORDER = "DECLINE_ORDER";
export const DECLINE_ORDER_SUCCESS = "DECLINE_ORDER_SUCCESS";
export const DECLINE_ORDER_FAILURE = "APPROVE_ORDER_FAILURE";
