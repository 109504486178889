import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { checkAdminAuthorization } from "./customMiddlewares";

import rootSaga from "../sagas";
import rootReducer from "./reducers";

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, thunk, checkAdminAuthorization];
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);
  store.dispatch({ type: "CHECK_AUTHORIZATION" });
  return store;
};

const store = configureStore();

export default store;
