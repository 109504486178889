export const GET_DELIVERY = "GET_DELIVERY";
export const GET_DELIVERY_SUCCESS = "GET_DELIVERY_SUCCESS";
export const GET_DELIVERY_FAILURE = "GET_DELIVERY_FAILURE";

export const GET_ALL_DELIVERIES = "GET_ALL_DELIVERIES";
export const GET_ALL_DELIVERIES_SUCCESS = "GET_ALL_DELIVERIES_SUCCESS";
export const GET_ALL_DELIVERIES_FAILURE = "GET_ALL_DELIVERIES_FAILURE";

export const CREATE_DELIVERY = "CREATE_DELIVERY";
export const CREATE_DELIVERY_SUCCESS = "CREATE_DELIVERY_SUCCESS";
export const CREATE_DELIVERY_FAILURE = "CREATE_DELIVERY_FAILURE";

export const UPDATE_DELIVERY = "UPDATE_DELIVERY";
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS";
export const UPDATE_DELIVERY_FAILURE = "UPDATE_DELIVERY_FAILURE";
