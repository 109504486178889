export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const GET_ALL_SETTLEMENTS = "GET_ALL_SETTLEMENTS";
export const GET_ALL_SETTLEMENTS_SUCCESS = "GET_ALL_SETTLEMENTS_SUCCESS";
export const GET_ALL_SETTLEMENTS_FAILURE = "GET_ALL_SETTLEMENTS_FAILURE";

export const GET_SETTLEMENT = "GET_SETTLEMENT";
export const GET_SETTLEMENT_SUCCESS = "GET_SETTLEMENT_SUCCESS";
export const GET_SETTLEMENT_FAILURE = "GET_SETTLEMENT_FAILURE";
