export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE";

export const SEARCH_PRODUCTS_BY_CATEGORY = "SEARCH_PRODUCTS_BY_CATEGORY";
export const SEARCH_PRODUCTS_BY_CATEGORY_SUCCESS =
  "SEARCH_PRODUCTS_BY_CATEGORY_SUCCESS";
export const SEARCH_PRODUCTS_BY_CATEGORY_FAILURE =
  "SEARCH_PRODUCTS_BY_CATEGORY_FAILURE";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const ACCEPT_PRODUCT_REQUEST = "ACCEPT_PRODUCT_REQUEST";
export const ACCEPT_PRODUCT_REQUEST_SUCCESS = "ACCEPT_PRODUCT_REQUEST_SUCCESS";
export const ACCEPT_PRODUCT_REQUEST_FAILURE = "ACCEPT_PRODUCT_REQUEST_FAILURE";

export const REJECT_PRODUCT_REQUEST = "REJECT_PRODUCT_REQUEST";
export const REJECT_PRODUCT_REQUEST_SUCCESS = "REJECT_PRODUCT_REQUEST_SUCCESS";
export const REJECT_PRODUCT_REQUEST_FAILURE = "REJECT_PRODUCT_REQUEST_FAILURE";

export const GET_ALL_REQUESTED_PRODUCTS = "GET_ALL_REQUESTED_PRODUCTS";
export const GET_ALL_REQUESTED_PRODUCTS_SUCCESS =
  "GET_ALL_REQUESTED_PRODUCTS_SUCCESS";
export const GET_ALL_REQUESTED_PRODUCTS_FAILURE =
  "GET_ALL_REQUESTED_PRODUCTS_FAILURE";
