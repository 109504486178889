import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectExpungingId } from "./expunge";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
});

export default rootReducer;

export const getAllSlidesByID = (store) => {
  return selectAllById(store.homepageSlide);
};

export const getAllSlides = (store) => {
  const sectionIds = selectAllId(store.homepageSlide);
  const byIds = selectAllById(store.homepageSlide);
  return sectionIds.map((id) => byIds[id]);
};

export const getSlideByID = (store, id) => {
  return selectById(store.homepageSlide, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.homepageSlide);
};

export const getExpungingId = (store) => {
  return (id) => selectExpungingId(store.homepageSlide, id);
};
