//HOMEPAGE SECTION
export const GET_ALL_HOMEPAGE_SECTIONS = "GET_ALL_HOMEPAGE_SECTIONS";
export const GET_ALL_HOMEPAGE_SECTIONS_SUCCESS =
  "GET_ALL_HOMEPAGE_SECTIONS_SUCCESS";
export const GET_ALL_HOMEPAGE_SECTIONS_FAILURE =
  "GET_ALL_HOMEPAGE_SECTIONS_FAILURE";

export const GET_HOMEPAGE_SECTION = "GET_HOMEPAGE_SECTION";
export const GET_HOMEPAGE_SECTION_SUCCESS = "GET_HOMEPAGE_SECTION_SUCCESS";
export const GET_HOMEPAGE_SECTION_FAILURE = "GET_HOMEPAGE_SECTION_FAILURE";

export const CREATE_HOMEPAGE_SECTION = "CREATE_HOMEPAGE_SECTION";
export const CREATE_HOMEPAGE_SECTION_SUCCESS =
  "CREATE_HOMEPAGE_SECTION_SUCCESS";
export const CREATE_HOMEPAGE_SECTION_FAILURE = "CREATE_HOMEPAGE_FAILURE";

export const UPDATE_HOMEPAGE_SECTION = "UPDATE_HOMEPAGE_SECTION";
export const UPDATE_HOMEPAGE_SECTION_SUCCESS =
  "UPDATE_HOMEPAGE_SECTION_SUCCESS";
export const UPDATE_HOMEPAGE_SECTION_FAILURE =
  "UPDATE_HOMEPAGE_SECTION_FAILURE";

export const DELETE_HOMEPAGE_SECTION = "DELETE_HOMEPAGE_SECTION";
export const DELETE_HOMEPAGE_SECTION_SUCCESS =
  "DELETE_HOMEPAGE_SECTION_SUCCESS";
export const DELETE_HOMEPAGE_SECTION_FAILURE =
  "DELETE_HOMEPAGE_SECTION_FAILURE";

export const ADD_ITEM_TO_SECTION = "ADD_ITEM_TO_SECTION";
export const ADD_ITEM_TO_SECTION_SUCCESS = "ADD_ITEM_TO_SECTION_SUCCESS";
export const ADD_ITEM_TO_SECTION_FAILURE = "ADD_ITEM_TO_SECTION_FAILURE";

export const REMOVE_ITEM_FROM_SECTION = "REMOVE_ITEM_FROM_SECTION";
export const REMOVE_ITEM_FROM_SECTION_SUCCESS =
  "REMOVE_ITEM_FROM_SECTION_SUCCESS";
export const REMOVE_ITEM_FROM_SECTION_FAILURE =
  "REMOVE_ITEM_FROM_SECTION_FAILURE";

//HOMEPAGE SLIDE
export const GET_ALL_HOMEPAGE_SLIDES = "GET_ALL_HOMEPAGE_SLIDES";
export const GET_ALL_HOMEPAGE_SLIDES_SUCCESS =
  "GET_ALL_HOMEPAGE_SLIDES_SUCCESS";
export const GET_ALL_HOMEPAGE_SLIDES_FAILURE =
  "GET_ALL_HOMEPAGE_SLIDES_FAILURE";

export const GET_HOMEPAGE_SLIDE = "GET_HOMEPAGE_SLIDE";
export const GET_HOMEPAGE_SLIDE_SUCCESS = "GET_HOMEPAGE_SLIDE_SUCCESS";
export const GET_HOMEPAGE_SLIDE_FAILURE = "GET_HOMEPAGE_SLIDE_FAILURE";

export const CREATE_HOMEPAGE_SLIDE = "CREATE_HOMEPAGE_SLIDE";
export const CREATE_HOMEPAGE_SLIDE_SUCCESS = "CREATE_HOMEPAGE_SLIDE_SUCCESS";
export const CREATE_HOMEPAGE_SLIDE_FAILURE = "CREATE_HOMEPAGE_SLIDE_FAILURE";

export const UPDATE_HOMEPAGE_SLIDE = "UPDATE_HOMEPAGE_SLIDE";
export const UPDATE_HOMEPAGE_SLIDE_SUCCESS = "UPDATE_HOMEPAGE_SLIDE_SUCCESS";
export const UPDATE_HOMEPAGE_SLIDE_FAILURE = "UPDATE_HOMEPAGE_SLIDE_FAILURE";

export const DELETE_HOMEPAGE_SLIDE = "DELETE_HOMEPAGE_SLIDE";
export const DELETE_HOMEPAGE_SLIDE_SUCCESS = "DELETE_HOMEPAGE_SLIDE_SUCCESS";
export const DELETE_HOMEPAGE_SLIDE_FAILURE = "DELETE_HOMEPAGE_SLIDE_FAILURE";
