import * as weeklyTipTypes from "../../actions/weeklyTip/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case weeklyTipTypes.GET_ALL_WEEKLYTIPS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case weeklyTipTypes.UPDATE_WEEKLYTIP_SUCCESS:
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.weeklyTip,
      };
      return newByIds;
    case weeklyTipTypes.CREATE_WEEKLYTIP_SUCCESS:
    case weeklyTipTypes.GET_WEEKLYTIP_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.weeklyTip,
      };
      return newByIds;
    }
    case weeklyTipTypes.DELETE_WEEKLYTIP_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};
