import { combineReducers } from "redux";

//Reducers
import app from "./app";
import auth from "./auth";
import blogPost from "./blogPost";
import weeklyTip from "./weeklyTip";
import product from "./product";
import category from "./category";
import order from "./order";
import customer from "./customer";
import supplier from "./supplier";
import image from "./image";
import homepageSection from "./app-content/homepage-section";
import homepageSlide from "./app-content/homepage-slide";
import alert from "./alert";
import delivery from "./delivery";
import transaction from "./transaction";
import settlement from "./settlement";
import requestedProduct from "./requestedProduct";
import partner from "./partner";
import survey from "./survey";
import surveyResponse from "./survey-response";
import config from "./config";

const rootReducer = combineReducers({
  alert,
  app,
  auth,
  blogPost,
  weeklyTip,
  category,
  customer,
  homepageSection,
  homepageSlide,
  image,
  order,
  product,
  supplier,
  delivery,
  transaction,
  settlement,
  requestedProduct,
  partner,
  survey,
  surveyResponse,
  config,
});

export default rootReducer;
