import * as surveyTypes from "../../actions/survey/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case surveyTypes.GET_ALL_SURVEYS_RESPONSE_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }

    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};
