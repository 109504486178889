import * as configTypes from "../../actions/config/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case configTypes.GET_ALL_MINIMUM_ORDER_CONFIG:
    // eslint-disable-next-line no-fallthrough
    case configTypes.SET_MINIMUM_ORDER_CONFIG:
      return "updating";
    case configTypes.GET_ALL_MINIMUM_ORDER_CONFIG_FAILURE:
    case configTypes.GET_ALL_MINIMUM_ORDER_CONFIG_SUCCESS:
    case configTypes.SET_MINIMUM_ORDER_CONFIG_FAILURE:
    case configTypes.SET_MINIMUM_ORDER_CONFIG_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};
