import { loginSuccessful, setProfile } from "./actions/auth/login";

export const checkAdminAuthorization = (store) => (next) => (action) => {
  if (action.type === "CHECK_AUTHORIZATION") {
    const token = localStorage.getItem("@Emedic:Token");
    const profile = localStorage.getItem("@Emedic:Profile");
    if (token) {
      store.dispatch(loginSuccessful());
      store.dispatch(setProfile(JSON.parse(profile)));
    }
  }
  next(action);
};
